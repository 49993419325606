<template>
  <div id="linkTransform">
    <vue-btn-return></vue-btn-return>
    <v-snackbar v-model="isSuccess" :timeout="timer" top right color="success">
      复制成功
      <!-- <v-btn text @click="isSuccess = false"> Close </v-btn> -->
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <div class="link__text mt-5" align="center" justify="center">
      <h2 class="mb-8">多开链接工具</h2>
    </div>
    <div class="box" align="center" justify="center">
      <div class="box-full">
        <v-textarea
          outlined
          name="ori"
          label="请输入链接"
          autofocus
          height="50vh"
          hide-details="false"
          no-resize
          v-model="links"
        ></v-textarea>
        <v-btn outlined color="primary" class="mt-2" @click="openLink()">打开</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import BtnReturn from "../components/BtnReturn";
export default {
  name: "LinkTransform",
  data: () => ({
    isSuccess: false,
    timer: 3000,
    links: "",
  }),
  methods: {
    openLink() {
      let arr = this.links.split('\n')
      for (let i = 0; i < arr.length; i++) {
        Object.assign(document.createElement("a"), {
        target: "_blank",
        href: arr[i]
        }).click()
      }
    }
  },
  components: {
    "vue-btn-return": BtnReturn,
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-full {
  width: 50%;
  text-align: right;
}

textarea {
  height: 50vh;
}
</style>